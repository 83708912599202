import { LoadingOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UnauthenticatedLayout from "../../components/layout/UnauthenticatedLayout";
import TextField from "../../components/ui/TextField";
import { signInUserEmailAndPassword } from "../../redux/actions/authActions";

function LoginScreen() {
  // Retrieve auth state from redux
  const authState = useSelector((state) => state.auth);
  const authActionStatus = authState.actionStatus;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const signInHandler = (e) => {
    e.preventDefault();
    dispatch(signInUserEmailAndPassword(email, password));
  };

  return (
    <UnauthenticatedLayout>
      <div className='flex flex-col justify-center w-full md:w-6/12 px-10 py-32  md:px-10 lg:px-14 2xl:px-24 bg-youthsOrange text-white'>
        {/*  */}
        <h4 className='text-4xl text-center'>Welcome Back</h4>
        <p className='mt-12 text-xl'>
          Nice to see you again. Create more events to get sponsored.
        </p>
      </div>
      <div className='flex flex-col w-full px-7 md:w-6/12'>
        <h3 className='text-4xl text-youthsOrange mt-7'>Log in</h3>
        <p className='mt-4'>
          Don't have an account yet? Let's{" "}
          <a href='/register' className='text-youthsOrange'>
            Sign Up
          </a>
        </p>
        <form className='flex flex-col w-full'>
          <TextField
            label='Email Address'
            type='email'
            placeholder='Email'
            autoComplete='username'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label='Password'
            type='password'
            placeholder='Password'
            autoComplete='current-password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className='bg-youthsOrange text-white my-4 py-2 rounded-lg  '
            onClick={(e) => signInHandler(e)}
            disabled={authActionStatus === "LOADING"}
          >
            {authActionStatus === "LOADING" ? <LoadingOutlined /> : "Sign In"}
          </button>
        </form>
        <p className='mt-4'>
          <a href='/forgot-password' className='text-youthsOrange'>
            Forget Password?
          </a>
        </p>
      </div>
    </UnauthenticatedLayout>
  );
}

export default LoginScreen;
