export const SIGN_UP_USER = "SIGN_UP_USER";
export const SIGN_IN_USER = "SIGN_IN_USER";
export const SIGN_OUT_USER = "SIGN_OUT_USER";
export const NOT_AUTHENTICATED = "NOT_AUTHENTICATED";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const ACTION_LOADING_AUTH = "ACTION_LOADING_AUTH";
export const ACTION_COMPLETE_AUTH = "ACTION_COMPLETE_AUTH";
export const ACTION_FAILED_AUTH = "ACTION_FAILED_AUTH";
export const ACTION_RESET_AUTH = "ACTION_RESET_AUTH";
