import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModalTemplate from "../../../components/ui/ModalTemplate";
import { firebaseAuth } from "../../../firebase";

function EditUserModal({ user, setShowEditUserModal, setUsers }) {
  const [updating, setUpdating] = useState(false);
  const [remark, setRemark] = useState("");

  const onCloseHandler = () => {
    setShowEditUserModal(false);
  };

  const updateClickHandler = () => {
    if (remark === "") {
      toast.error("Please provide a remark");
      return;
    }
    setUpdating(true);
    firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
      // Update user on backend
      try {
        const { data } = await axios.put(
          `${process.env.REACT_APP_BACKEND}/admin/user`,
          {
            user,
            remark,
          },
          {
            headers: {
              Authorization: "Bearer " + idToken,
            },
          }
        );
        if (data) {
          toast.success("User updated");
          setUpdating(false);
          setUsers([]);
          setShowEditUserModal(false);
        } else {
          toast.error("Error updating user");
          setUpdating(false);
        }
      } catch (error) {
        toast.error("Error updating user");
        setUpdating(false);
      }
    });
  };

  useEffect(() => {
    if (user.remark) {
      setRemark(user.remark);
    }
  }, [user]);

  return (
    <ModalTemplate title='Edit User Remarks' onClose={onCloseHandler}>
      <div className='flex flex-col'>
        <p>Name: {user.fullName}</p>
        <p>Email: {user.email}</p>
        <label className='my-4'>Remarks</label>
        <textarea
          className='border p-2 rounded-lg'
          value={remark}
          rows={3}
          onChange={(e) => setRemark(e.target.value)}
        />
        <button
          className='bg-youthsOrange text-white px-8 py-2 rounded-lg my-2'
          onClick={() => updateClickHandler()}
          disabled={updating}
        >
          {updating ? <LoadingOutlined /> : "Update"}
        </button>
      </div>
    </ModalTemplate>
  );
}

export default EditUserModal;
