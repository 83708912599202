import { LoadingOutlined } from "@ant-design/icons";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Layout from "../../components/layout/Layout";
import TextField from "../../components/ui/TextField";
import { firebaseAuth } from "../../firebase";

function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const [changePassword, setChangePassword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const changePasswordHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (changePassword.newPassword !== changePassword.confirmPassword) {
      toast.error("New passwords do not match");
      return;
    }
    const credential = EmailAuthProvider.credential(
      firebaseAuth.currentUser.email,
      changePassword.currentPassword
    );
    // Reauthenticate the user for password update
    reauthenticateWithCredential(firebaseAuth.currentUser, credential)
      .then(() => {
        // Update the password
        updatePassword(firebaseAuth.currentUser, changePassword.newPassword)
          .then(() => {
            // Password update successful
            toast.success("Password Updated");
            setChangePassword({
              currentPassword: "",
              newPassword: "",
              confirmPassword: "",
            });
            setLoading(false);
          })
          .catch((error) => {
            // Error occurred with update password
            toast.error("Error updating password");
            setLoading(false);
          });
      })
      .catch((error) => {
        // Error occured with reauthenticating user
        toast.error("Error updating credential");
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <Layout title='Change Password'>
      <form className='flex flex-col w-4/12 mt-4'>
        <TextField
          type='password'
          label='Current Password'
          showLabel
          value={changePassword.currentPassword}
          onChange={(e) =>
            setChangePassword({
              ...changePassword,
              currentPassword: e.target.value,
            })
          }
        />
        <TextField
          type='password'
          label='New Password'
          showLabel
          value={changePassword.newPassword}
          onChange={(e) =>
            setChangePassword({
              ...changePassword,
              newPassword: e.target.value,
            })
          }
        />
        <TextField
          type='password'
          label='Confirm New Password'
          showLabel
          value={changePassword.confirmPassword}
          onChange={(e) =>
            setChangePassword({
              ...changePassword,
              confirmPassword: e.target.value,
            })
          }
        />
        <button
          className='bg-youthsOrange text-white px-8 py-2 rounded-lg'
          onClick={(e) => changePasswordHandler(e)}
          disabled={loading}
        >
          {loading ? <LoadingOutlined /> : "Save"}
        </button>
      </form>
    </Layout>
  );
}

export default ChangePassword;
