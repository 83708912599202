import axios from "axios";
import { toast } from "react-toastify";
import { firebaseAuth } from "../../firebase";
import * as t from "../constants/eventConstants";

// Retrieve the users events from backend and pass to redux state
export const getEvents = () => async (dispatch) => {
  dispatch({ type: t.ACTION_LOADING_EVENT });
  // Retrieve firebase user token
  firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
    try {
      // Retrieve events from backend
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND}/event`,
        {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        }
      );
      // Dispatch events to redux state
      dispatch({
        type: t.GET_EVENTS,
        payload: data,
      });
      dispatch({
        type: t.ACTION_COMPLETE_EVENT,
      });
    } catch (error) {
      toast.error("Error retrieving events");
      dispatch({
        type: t.ACTION_FAILED_EVENT,
      });
    }
    dispatch({ type: t.ACTION_RESET_EVENT });
  });
};

// Create event record on backend and update redux state
export const createEvent = (event) => async (dispatch) => {
  dispatch({ type: t.ACTION_LOADING_EVENT });
  // Retrieve firebase user token
  firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
    try {
      // Create event record on backend
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND}/event`,
        {
          event,
        },
        {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        }
      );
      // Dispatch created event to update redux state
      dispatch({
        type: t.CREATE_EVENT,
        payload: data,
      });
      toast.success("Event Created");
      dispatch({ type: t.ACTION_COMPLETE_EVENT });
    } catch (error) {
      console.log(error);
      toast.error("Error creating event");
      dispatch({ type: t.ACTION_FAILED_EVENT });
    }
    setTimeout(() => {
      dispatch({ type: t.ACTION_RESET_EVENT });
    }, 1000);
  });
};

// Update event record on backend and update redux state
export const updateEvent = (event) => async (dispatch) => {
  dispatch({ type: t.ACTION_LOADING_EVENT });
  // Retrieve firebase user token
  firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
    // Update event on backend
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND}/event`,
        {
          event,
        },
        {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        }
      );
      // dispatch updated event to update redux state
      dispatch({
        type: t.UPDATE_EVENT,
        payload: data,
      });
      toast.success("Event updated");
      dispatch({
        type: t.ACTION_COMPLETE_EVENT,
      });
    } catch (error) {
      toast.error("Error updating event");
      dispatch({ type: t.ACTION_FAILED_EVENT });
    }
    setTimeout(() => {
      dispatch({ type: t.ACTION_RESET_EVENT });
    }, 1000);
  });
};

// Delete event record on backend and update redux state
export const deleteEvent = (event) => async (dispatch) => {
  dispatch({ type: t.ACTION_LOADING_EVENT });
  // Retrieve firebase user token
  firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_BACKEND}/event`,
        {
          headers: {
            Authorization: "Bearer " + idToken,
          },
          data: {
            event,
          },
        }
      );
      // Update redux state
      dispatch({
        type: t.DELETE_EVENT,
        payload: data,
      });
      dispatch({ type: t.ACTION_COMPLETE_EVENT });
      toast.success("Event Deleted");
    } catch (error) {
      dispatch({
        type: t.ACTION_FAILED_EVENT,
      });
      toast.error("Error deleting event");
    }
    dispatch({ type: t.ACTION_RESET_EVENT });
  });
};

// View Event sets redux state to the event user has selected to view
export const viewEvent = (event) => async (dispatch) => {
  dispatch({ type: t.VIEW_EVENT, payload: event });
};
