import React from "react";
import {
  CalendarOutlined,
  ClockCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { formattedDate } from "../../../utils/formattedDate";
import { useDispatch, useSelector } from "react-redux";
import { deleteEvent, viewEvent } from "../../../redux/actions/eventActions";
import { useNavigate } from "react-router-dom";

function EventCard({ event }) {
  const eventState = useSelector((state) => state.event);
  const eventActionStatus = eventState.actionStatus;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const viewEventHandler = () => {
    dispatch(viewEvent(event));
    navigate(`/view-event/${event.id}`);
  };

  const deleteEventHandler = () => {
    dispatch(deleteEvent(event));
  };
  return (
    <div className='flex justify-between border border-black rounded-xl grow m-2 lg:m-4 px-2 py-2 lg:px-8 lg:py-8'>
      {/* Card Left */}
      <div className='flex flex-col justify-around'>
        <div className='flex space-x-1 lg:space-x-4 items-center text-lg md:text-2xl'>
          <CalendarOutlined />
          <p>Event Name: {event.eventTitle}</p>
        </div>
        <div className='flex space-x-1 lg:space-x-4 items-center text-lg md:text-2xl'>
          <ClockCircleOutlined />
          <p>Event Date: {formattedDate(event.eventStartDate)}</p>
        </div>
      </div>
      {/* Card Right */}
      <div className='flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0 sm:space-x-4'>
        <button
          className='bg-youthsOrange hover:shadow-lg h-10 text-white px-2 sm:px-8 py-1 md:py-2 md:my-2 rounded-lg'
          onClick={() => viewEventHandler()}
        >
          View
        </button>
        <button
          className='text-youthsGrey hover:shadow-lg h-10 px-2 sm:px-8 py-1 md:py-2 md:my-10 rounded-lg border border-youthsGrey'
          onClick={() => deleteEventHandler()}
          disabled={eventActionStatus === "LOADING"}
        >
          {eventActionStatus === "LOADING" ? <LoadingOutlined /> : "Delete"}
        </button>
      </div>
    </div>
  );
}

export default EventCard;
