import React from "react";
import { useDispatch } from "react-redux";
import { signOutUser } from "../../../../redux/actions/authActions";
import { MenuOutlined } from "@ant-design/icons";
function Header({ setShowMobileSidebar }) {
  const dispatch = useDispatch();

  const showMobileSidebarHandler = () => {
    setShowMobileSidebar(true);
  };

  const signOutHandler = () => {
    dispatch(signOutUser());
  };

  return (
    <div className='flex justify-between lg:justify-end items-center w-full bg-youthsGrey px-8 py-3 shadow-xl'>
      <button
        className='flex lg:hidden flex-col items-center pb-1 justify-center w-10 h-10'
        onClick={() => showMobileSidebarHandler()}
      >
        <MenuOutlined className='text-youthsOrange text-xl' />
      </button>
      <button
        className='h-10 w-24 bg-youthsOrange text-white'
        onClick={() => signOutHandler()}
      >
        Sign Out
      </button>
    </div>
  );
}

export default Header;
