import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../../logo.png";

function UnauthenticatedHeader({ hideSignUp }) {
  const navigate = useNavigate();
  return (
    <div className='flex justify-between py-4 px-7 shadow-md'>
      <img src={logo} alt='Logo' />
      <div className='hidden md:flex w-4/12 lg:w-2/12 justify-between items-center'>
        <button
          className='h-10 w-24 bg-youthsGrey text-white rounded-md'
          onClick={() => navigate("/")}
        >
          Login
        </button>
        {!hideSignUp && (
          <button
            className='h-10 w-24 border-2 border-youthsGrey rounded-md'
            onClick={() => navigate("/register")}
          >
            Sign Up
          </button>
        )}
      </div>
    </div>
  );
}

export default UnauthenticatedHeader;
