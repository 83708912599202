import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { firebaseAuth } from "../../../firebase";
import { downloadCSVFile } from "../../../utils/downloadCSVFile";
import { formattedDate } from "../../../utils/formattedDate";
import { searchTable } from "../../../utils/tableSearch";
import EditUserModal from "./EditUserModal";

function UserTable({ users, setUsers }) {
  const [paginationStart, setPaginationStart] = useState(0);
  const [paginationEnd, setPaginationEnd] = useState(25);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationResults, setPaginationResults] = useState(25);
  const [usersToDisplay, setUsersToDisplay] = useState([]);
  const [goToPage, setGoToPage] = useState(1);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [loading, setLoading] = useState(false);

  var totalPages = +Math.ceil(filteredUsers.length / paginationResults).toFixed(
    0
  );

  const searchHandler = (searchTerm) => {
    console.log(filteredUsers);
    const results = searchTable(searchTerm, users);
    setFilteredUsers(results);
    selectPaginationPage(1);
  };

  const exportHandler = () => {
    var data = [];
    var rows = document.querySelectorAll("table tr");

    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");

      for (var j = 0; j < cols.length; j++) {
        row.push(cols[j].innerText);
      }

      data.push(row.join(","));
    }

    downloadCSVFile(data.join("\n"), "User Data");
  };

  const editUserHandler = (user) => {
    setEditUser(user);
    setShowEditUserModal(true);
  };

  const setAdminHandler = (user, status) => {
    setLoading(true);
    firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
      // Update user on backend
      try {
        const { data } = await axios.put(
          `${process.env.REACT_APP_BACKEND}/admin/user/admin`,
          {
            user,
            status,
          },
          {
            headers: {
              Authorization: "Bearer " + idToken,
            },
          }
        );
        if (data) {
          toast.success("User updated");
          setLoading(false);
          setUsers([]);
        } else {
          toast.error("Error updating user");
          setLoading(false);
        }
      } catch (error) {
        toast.error("Error updating user");
        setLoading(false);
      }
    });
  };

  const nextPaginationHandler = () => {
    if (paginationPage === totalPages) {
      return;
    }
    const nextPaginationPage = paginationPage + 1;
    const nextPaginationStart =
      nextPaginationPage * paginationResults - paginationResults;
    const nextPaginationEnd = nextPaginationStart + +paginationResults;

    setPaginationStart(nextPaginationStart);
    setPaginationEnd(nextPaginationEnd);
    setPaginationPage(nextPaginationPage);
  };

  const previousPaginationHandler = () => {
    if (paginationPage === 1) {
      return;
    }
    const nextPaginationPage = paginationPage - 1;
    const nextPaginationStart =
      nextPaginationPage * paginationResults - paginationResults;
    const nextPaginationEnd = nextPaginationStart + +paginationResults;

    setPaginationStart(nextPaginationStart);
    setPaginationEnd(nextPaginationEnd);
    setPaginationPage(nextPaginationPage);
  };

  const selectPaginationPage = (page) => {
    const nextPaginationStart = page * paginationResults - paginationResults;
    const nextPaginationEnd = nextPaginationStart + +paginationResults;

    console.log("Page: ", page);
    console.log("Results ", paginationResults);
    console.log("Start ", nextPaginationStart);
    console.log("End ", nextPaginationEnd);

    setPaginationStart(nextPaginationStart);
    setPaginationEnd(nextPaginationEnd);
    setPaginationPage(page);
  };

  const goToPageHandler = (e) => {
    e.preventDefault();
    if (goToPage > totalPages) {
      toast.error("Page does not exist");
      return;
    }

    const nextPaginationStart =
      goToPage * paginationResults - paginationResults;
    const nextPaginationEnd = nextPaginationStart + +paginationResults;

    console.log("Results ", paginationResults);
    console.log("Start ", nextPaginationStart);
    console.log("End ", nextPaginationEnd);

    setPaginationStart(nextPaginationStart);
    setPaginationEnd(nextPaginationEnd);
    setPaginationPage(goToPage);
    console.log(goToPage);
  };

  const mapPageButtons = () => {
    let buttons = [];
    const pageLimit = () => {
      if (totalPages > 5) {
        const page = paginationPage + 5;
        if (page > totalPages) {
          return totalPages + 1;
        } else {
          return page;
        }
      } else {
        return totalPages + 1;
      }
    };

    const pageStart = () => {
      if (paginationPage < 6) {
        return 1;
      } else {
        return paginationPage;
      }
    };
    for (let i = pageStart(); i < pageLimit(); i++) {
      buttons.push(
        <button
          key={i}
          className={
            paginationPage === i
              ? "w-6 h-6 border bg-youthsGrey text-white"
              : "w-6 h-6 border"
          }
          onClick={() => selectPaginationPage(i)}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  // Use effect to set events to display based on changes to results state
  useEffect(() => {
    setPaginationStart(0);
    setPaginationEnd(paginationResults);
    setPaginationPage(1);
  }, [paginationResults]);

  // Use effect to set events to display based on changes to pagination state
  useEffect(() => {
    setUsersToDisplay(filteredUsers?.slice(paginationStart, paginationEnd));
  }, [filteredUsers, paginationStart, paginationEnd]);

  return (
    <div>
      <div className='flex space-x-4 justify-end items-center'>
        {/* SEARCH */}
        <p className='text-2xl font-semibold'>Search: </p>
        <div className='flex border items-center px-2'>
          <input
            type='text'
            placeholder='Your name'
            className='outline-none p-2'
            onChange={(e) => searchHandler(e.target.value)}
          />
          <SearchOutlined />
        </div>
      </div>
      <div className='flex justify-between items-center mt-8 mb-2 text-youthsGrey'>
        {/* Total */}
        <p className='text-2xl font-semibold pb-4'>
          Total Users: {users.length}
        </p>
        <button
          className='bg-youthsGrey text-white py-3 px-12 rounded-md'
          onClick={() => exportHandler()}
        >
          Export
        </button>
      </div>
      <table className='table-auto border w-full mx-auto'>
        <thead>
          <tr className='bg-slate-200 text-youthsGrey text-xs'>
            <th className='py-4'>#</th>
            <th></th>
            <th>Remark</th>
            <th>Name</th>
            <th>Gender</th>
            <th>Email</th>
            <th>DOB</th>
            <th>Mobile No.</th>
            <th>Country</th>
            <th>Ethnicity</th>
            <th>Telco Type</th>
            <th>Telco Plan</th>
            <th>Qualification</th>
            <th>School Location</th>
            <th>School Name</th>
            <th>Field of Study</th>
            <th>Major</th>
            <th>Est Graduation Date</th>
            <th>Admin</th>
          </tr>
        </thead>
        <tbody>
          {/* Below checks users is not null and then maps each user returned to the table */}
          {users &&
            usersToDisplay?.map((row, index) => (
              <tr
                key={row.id}
                className={
                  index % 2
                    ? "bg-slate-100 text-center text-xs h-10"
                    : "bg-slate-300 text-center text-xs h-10"
                }
              >
                <td className=''>{index + 1}</td>
                <td className=''>
                  <button
                    className='bg-slate-700 disabled:bg-slate-200 text-white py-1 px-2'
                    onClick={() => editUserHandler(row)}
                    disabled={loading}
                  >
                    Edit
                  </button>
                </td>
                <td>{row.remark}</td>
                <td>{row.fullName}</td>
                <td>{row.gender === "MALE" ? "Male" : "Female"}</td>
                <td>{row.email}</td>
                <td>{formattedDate(row.dateOfBirth)}</td>
                <td>{row.mobileNumber}</td>
                <td>{row.country}</td>
                <td>{row.ethnicity}</td>
                <td>{row.telcoType}</td>
                <td>{row.telconPlan}</td>
                <td>{row.educationDetails?.qualification}</td>
                <td>{row.educationDetails?.schoolLocation}</td>
                <td>{row.educationDetails?.schoolName}</td>
                <td>{row.educationDetails?.fieldOfStudy}</td>
                <td>{row.educationDetails?.major}</td>
                <td>
                  {formattedDate(row.educationDetails?.estimateGraduationDate)}
                </td>
                <td>
                  {" "}
                  {!row.isAdmin && (
                    <button
                      className='bg-slate-700 disabled:bg-slate-200 text-white py-1 px-2'
                      onClick={() => setAdminHandler(row, true)}
                      disabled={loading}
                    >
                      Grant
                    </button>
                  )}
                  {row.isAdmin && (
                    <button
                      className='bg-slate-700 disabled:bg-slate-200 text-white py-1 px-2'
                      onClick={() => setAdminHandler(row, false)}
                      disabled={loading}
                    >
                      Remove
                    </button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {/* Pagination */}
      <div className='absolute right-0 mt-4 flex w-6/12 justify-between px-4 text-youthsLightGrey'>
        <div className='flex items-center space-x-4'>
          <p>Show records</p>
          <select
            value={paginationResults}
            onChange={(e) => setPaginationResults(e.target.value)}
            className='border h-6 w-14 text-center'
          >
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={users.length}>ALL</option>
          </select>
        </div>
        <div className={totalPages === 1 ? "invisible" : "space-x-2"}>
          <button
            className='w-6 h-6 border disabled:invisible'
            onClick={() => previousPaginationHandler()}
            disabled={paginationPage === 1}
          >
            &larr;
          </button>
          {mapPageButtons()}
          <button
            className='w-6 h-6 border disabled:invisible'
            onClick={() => nextPaginationHandler()}
            disabled={paginationPage === totalPages}
          >
            &rarr;
          </button>
        </div>
        <form className={totalPages === 1 ? "invisible" : "flex space-x-2"}>
          <label>Go to page</label>
          <input
            type='number'
            className='border h-6 w-12 text-center disabled:invisible'
            min={1}
            max={totalPages}
            value={goToPage}
            onChange={(e) => setGoToPage(e.target.valueAsNumber)}
          />
          <button
            className='bg-youthsGrey text-white w-7 h-6 disabled:invisible'
            onClick={(e) => goToPageHandler(e)}
          >
            Go
          </button>
        </form>
      </div>
      {showEditUserModal && (
        <EditUserModal
          user={editUser}
          setShowEditUserModal={setShowEditUserModal}
          setUsers={setUsers}
        />
      )}
    </div>
  );
}

export default UserTable;
