import React from "react";

function TextField({
  label,
  value,
  type,
  placeholder,
  autoComplete,
  onChange,
  disabled,
  showLabel,
  flexRow,
}) {
  return (
    <div
      className={
        flexRow
          ? "flex justify-between items-center mt-2 mb-4"
          : "flex flex-col mt-2 mb-4"
      }
    >
      {showLabel && <label className='mb-2'>{label}</label>}
      <input
        className={
          flexRow
            ? "w-8/12 p-2 h-10 border rounded-lg"
            : "w-full p-2 h-10 border rounded-lg"
        }
        value={value}
        type={type}
        placeholder={placeholder}
        autoComplete={autoComplete}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
}

export default TextField;
