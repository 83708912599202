import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UnauthenticatedLayout from "../../components/layout/UnauthenticatedLayout";
import SelectField from "../../components/ui/SelectField";
import TextField from "../../components/ui/TextField";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { updateUser } from "../../redux/actions/authActions";
// Import constants for select field
import {
  countryList,
  ethnicityOptions,
  telcoPlanOptions,
  telcoTypeOptions,
} from "../../utils/selectOptions";
import { LoadingOutlined } from "@ant-design/icons";

function RegisterStepOne() {
  // Retrieve auth state from redux
  const authState = useSelector((state) => state.auth);
  const authActionStatus = authState.actionStatus;
  const user = authState.user;

  const [editUser, setEditUser] = useState({
    fullName: "",
    email: "",
    gender: "MALE",
    dateOfBirth: new Date(),
    mobileNumber: "",
    country: "",
    ethnicity: "",
    telcoType: "",
    telconPlan: "",
  });

  const dispatch = useDispatch();

  const nextClickHandler = (e) => {
    e.preventDefault();
    if (editUser.fullName.trim() === "") {
      toast.error("Please complete name field");
      return;
    }
    if (editUser.email.trim() === "") {
      toast.error("Please complete email field");
      return;
    }
    if (editUser.mobileNumber.trim() === "") {
      toast.error("Please complete number field");
      return;
    }
    if (editUser.country.trim() === "") {
      toast.error("Please complete country field");
      return;
    }
    if (editUser.ethnicity.trim() === "") {
      toast.error("Please complete ethnicity field");
      return;
    }
    if (editUser.telcoType.trim() === "") {
      toast.error("Please complete telco type field");
      return;
    }
    if (editUser.telconPlan.trim() === "") {
      toast.error("Please complete telcon plan field");
      return;
    }
    console.log(editUser);
    dispatch(updateUser(editUser));
  };

  useEffect(() => {
    setEditUser((prevState) => ({
      ...prevState,
      fullName: user.fullName,
      email: user.email,
      gender: user.gender,
      dateOfBirth: new Date(user.dateOfBirth),
    }));
  }, [user]);

  return (
    <UnauthenticatedLayout hideSignUp>
      <div className='flex flex-col items-center w-full px-2'>
        {/* Step Bar */}
        <div className='flex w-full border border-youthsGrey sm:p-3'>
          <div className='flex flex-col w-6/12 items-center justify-center bg-youthsOrange text-white lg:text-lg xl:text-xl py-8'>
            <p>Step One:</p>
            <p>User Information</p>
          </div>
          <div className='flex flex-col w-6/12 items-center justify-center lg:text-lg xl:text-xl py-8'>
            <p>Step Two:</p>
            <p>Education</p>
          </div>
        </div>
        {/* Form */}
        <form className='flex flex-col w-full lg:flex-row justify-between mt-4'>
          {/* Form Left (Form Top on mobile) */}
          <div className='flex flex-col w-full lg:w-6/12 px-2'>
            <TextField
              type='text'
              label='Name:'
              showLabel
              flexRow
              value={editUser.fullName}
              onChange={(e) =>
                setEditUser({ ...editUser, fullName: e.target.value })
              }
            />
            <div className='flex justify-between mt-2 mb-4 items-center'>
              <label className='mb-2'>Gender:</label>
              <div className='flex space-x-8 w-8/12'>
                <input
                  type='radio'
                  id='male'
                  name='gender'
                  value='MALE'
                  checked={editUser.gender === "MALE"}
                  onChange={() => setEditUser({ ...editUser, gender: "MALE" })}
                />
                <label htmlFor='male'>Male</label>
                <input
                  type='radio'
                  id='female'
                  name='gender'
                  value='FEMALE'
                  checked={editUser.gender === "FEMALE"}
                  onChange={() =>
                    setEditUser({ ...editUser, gender: "FEMALE" })
                  }
                />
                <label htmlFor='female'>Female</label>
              </div>
            </div>
            <TextField
              type='email'
              label='Email:'
              showLabel
              flexRow
              value={editUser.email}
              onChange={(e) =>
                setEditUser({ ...editUser, email: e.target.value })
              }
            />
            <TextField
              type='date'
              label='DOB:'
              showLabel
              flexRow
              placeholder='Date of Birth'
              value={format(new Date(editUser.dateOfBirth), "yyyy-MM-dd")}
              onChange={(e) =>
                setEditUser({ ...editUser, dateOfBirth: e.target.value })
              }
            />
            <TextField
              type='tel'
              label='Mobile Number:'
              showLabel
              flexRow
              placeholder='016-1234567'
              value={editUser.mobileNumber}
              onChange={(e) =>
                setEditUser({ ...editUser, mobileNumber: e.target.value })
              }
            />
          </div>
          {/* Form Right (Form Bottom on mobile) */}
          <div className='flex flex-col w-full lg:w-6/12 px-2'>
            <SelectField
              label='Country:'
              showLabel
              flexRow
              value={editUser.country}
              placeholder='Select country'
              options={countryList}
              onChange={(e) =>
                setEditUser({ ...editUser, country: e.target.value })
              }
            />
            <SelectField
              label='Ethnicity:'
              showLabel
              flexRow
              value={editUser.ethnicity}
              placeholder='Select ethnicity'
              options={ethnicityOptions}
              onChange={(e) =>
                setEditUser({ ...editUser, ethnicity: e.target.value })
              }
            />
            <SelectField
              label='Telco Type:'
              showLabel
              flexRow
              value={editUser.telcoType}
              placeholder='Select telco type'
              options={telcoTypeOptions}
              onChange={(e) =>
                setEditUser({ ...editUser, telcoType: e.target.value })
              }
            />
            <SelectField
              label='Telcon Plan:'
              showLabel
              flexRow
              value={editUser.telconPlan}
              placeholder='Select telcon plan'
              options={telcoPlanOptions}
              onChange={(e) =>
                setEditUser({ ...editUser, telconPlan: e.target.value })
              }
            />
            <div className='flex justify-end w-full'>
              <button
                className='bg-youthsOrange text-white px-8 py-2 rounded-lg'
                onClick={(e) => nextClickHandler(e)}
                disabled={authActionStatus === "LOADING"}
              >
                {authActionStatus === "LOADING" ? <LoadingOutlined /> : "Next"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </UnauthenticatedLayout>
  );
}

export default RegisterStepOne;
