import React, { useState } from "react";
import logo from "../../../../logo.png";
import {
  BankOutlined,
  CalendarOutlined,
  CloseCircleOutlined,
  DollarOutlined,
  DownOutlined,
  EditOutlined,
  LockOutlined,
  ReadOutlined,
  ScheduleOutlined,
  SettingOutlined,
  TeamOutlined,
  UpOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function MobileDashboardSidebar({ setShowMobileSidebar }) {
  const authState = useSelector((state) => state.auth);
  const user = authState.user;
  const [myProfileExpanded, setMyProfileExpanded] = useState(false);
  const [sponsorshipExpanded, setSponsorshipExpanded] = useState(false);
  const [settingsExpanded, setSettingsExpanded] = useState(false);
  const [adminExpanded, setAdminExpanded] = useState(false);

  const navigate = useNavigate();

  // Custom tailwind classes used below to avoid duplicating code and to simplify amendments.
  // Amendments can be made in index.css file

  return (
    <div className='flex lg:hidden bg-white fixed inset-0 z-50'>
      <div className='flex flex-col w-full shadow-xl z-50'>
        <div className='flex items-center justify-between px-8 py-20'>
          <img src={logo} alt='Logo' />
          <button onClick={() => setShowMobileSidebar(false)}>
            <CloseCircleOutlined className='text-3xl text-youthsOrange' />
          </button>
        </div>

        {/* My Profile Button */}
        <button
          className='mobileSidebarButton'
          onClick={() => setMyProfileExpanded(!myProfileExpanded)}
        >
          <div className='mobileSidebarButtonDiv'>
            <UserOutlined style={{ fontSize: "30px" }} />
            <p>My Profile</p>
          </div>
          {myProfileExpanded && <UpOutlined />}
          {!myProfileExpanded && <DownOutlined />}
        </button>
        {/* My Profile Sub Buttons */}
        {myProfileExpanded && (
          <div className='mobileSubButtonContainer'>
            {/* Edit Profile Sub Button */}
            <button className='mobileSubButton' onClick={() => navigate("/")}>
              <EditOutlined />
              <p>Edit Profile</p>
            </button>
            {/* Education Sub Button */}
            <button
              className='mobileSubButton'
              onClick={() => navigate("/education")}
            >
              <ReadOutlined />
              <p>Education</p>
            </button>
          </div>
        )}
        {/* Sponsorship Button */}
        <button
          className='mobileSidebarButton'
          onClick={() => setSponsorshipExpanded(!sponsorshipExpanded)}
        >
          <div className='mobileSidebarButtonDiv'>
            <DollarOutlined style={{ fontSize: "30px" }} />
            <p>Sponsorship</p>
          </div>
          {sponsorshipExpanded && <UpOutlined />}
          {!sponsorshipExpanded && <DownOutlined />}
        </button>
        {/*Sponsorship Sub Buttons */}
        {sponsorshipExpanded && (
          <div className='mobileSubButtonContainer'>
            {/* Create Event Sub Button */}
            <button
              className='mobileSubButton'
              onClick={() => navigate("/create-event")}
            >
              <ScheduleOutlined />
              <p>Create Event</p>
            </button>
            {/* My Events Sub Button */}
            <button
              className='mobileSubButton'
              onClick={() => navigate("/my-events")}
            >
              <CalendarOutlined />
              <p>My Events</p>
            </button>
          </div>
        )}

        {/* Settings Button */}
        <button
          className='mobileSidebarButton'
          onClick={() => setSettingsExpanded(!settingsExpanded)}
        >
          <div className='mobileSidebarButtonDiv'>
            <SettingOutlined style={{ fontSize: "30px" }} />
            <p>Settings</p>
          </div>
          {settingsExpanded && <UpOutlined />}
          {!settingsExpanded && <DownOutlined />}
        </button>
        {/*Settings Sub Buttons */}
        {settingsExpanded && (
          <div className='mobileSubButtonContainer'>
            {/* Change Password Sub Button */}
            <button
              className='mobileSubButton'
              onClick={() => navigate("/change-password")}
            >
              <LockOutlined />
              <p>Change Password</p>
            </button>
          </div>
        )}
        {/* Admin Button */}
        {user.isAdmin && (
          <>
            <button
              className='mobileSidebarButton'
              onClick={() => setAdminExpanded(!adminExpanded)}
            >
              <div className='mobileSidebarButtonDiv'>
                <BankOutlined style={{ fontSize: "30px" }} />

                <p>Admin</p>
              </div>
              {adminExpanded && <UpOutlined />}
              {!adminExpanded && <DownOutlined />}
            </button>
            {/*Settings Sub Buttons */}
            {adminExpanded && (
              <div className='mobileSubButtonContainer'>
                {/* Events Sub Button */}
                <button
                  className='mobileSubButton'
                  onClick={() => navigate("/admin/events")}
                >
                  <ScheduleOutlined />
                  <p>Events</p>
                </button>
                {/* Users Sub Button */}
                <button
                  className='mobileSubButton'
                  onClick={() => navigate("/admin/users")}
                >
                  <TeamOutlined />
                  <p>Users</p>
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default MobileDashboardSidebar;
