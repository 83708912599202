import React, { useState } from "react";
import logo from "../../../../logo.png";
import {
  BankOutlined,
  CalendarOutlined,
  DollarOutlined,
  DownOutlined,
  EditOutlined,
  LockOutlined,
  ReadOutlined,
  ScheduleOutlined,
  SettingOutlined,
  TeamOutlined,
  UpOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function DashboardSidebar() {
  const authState = useSelector((state) => state.auth);
  const user = authState.user;
  const [myProfileExpanded, setMyProfileExpanded] = useState(false);
  const [sponsorshipExpanded, setSponsorshipExpanded] = useState(false);
  const [settingsExpanded, setSettingsExpanded] = useState(false);
  const [adminExpanded, setAdminExpanded] = useState(false);

  const navigate = useNavigate();

  // Custom tailwind classes used below to avoid duplicating code and to simplify amendments.
  // Amendments can be made in index.css file

  return (
    <div className='hidden lg:flex flex-col w-3/12 xl:w-2/12 shadow-xl'>
      <div className='px-8 py-20'>
        <img src={logo} alt='Logo' />
      </div>
      {/* My Profile Button */}
      <button
        className='sidebarButton'
        onClick={() => setMyProfileExpanded(!myProfileExpanded)}
      >
        <div className='sidebarButtonDiv'>
          <UserOutlined style={{ fontSize: "30px" }} />
          <p>My Profile</p>
        </div>
        {myProfileExpanded && <UpOutlined />}
        {!myProfileExpanded && <DownOutlined />}
      </button>
      {/* My Profile Sub Buttons */}
      {myProfileExpanded && (
        <div className='sidebarSubButtonContainer'>
          {/* Edit Profile Sub Button */}
          <button className='sidebarSubButton' onClick={() => navigate("/")}>
            <EditOutlined />
            <p>Edit Profile</p>
          </button>
          {/* Education Sub Button */}
          <button
            className='sidebarSubButton'
            onClick={() => navigate("/education")}
          >
            <ReadOutlined />
            <p>Education</p>
          </button>
        </div>
      )}
      {/* Sponsorship Button */}
      <button
        className='sidebarButton'
        onClick={() => setSponsorshipExpanded(!sponsorshipExpanded)}
      >
        <div className='sidebarButtonDiv'>
          <DollarOutlined style={{ fontSize: "30px" }} />
          <p>Sponsorship</p>
        </div>
        {sponsorshipExpanded && <UpOutlined />}
        {!sponsorshipExpanded && <DownOutlined />}
      </button>
      {/*Sponsorship Sub Buttons */}
      {sponsorshipExpanded && (
        <div className='sidebarSubButtonContainer'>
          {/* Create Event Sub Button */}
          <button
            className='sidebarSubButton'
            onClick={() => navigate("/create-event")}
          >
            <ScheduleOutlined />
            <p>Create Event</p>
          </button>
          {/* My Events Sub Button */}
          <button
            className='sidebarSubButton'
            onClick={() => navigate("/my-events")}
          >
            <CalendarOutlined />
            <p>My Events</p>
          </button>
        </div>
      )}

      {/* Settings Button */}

      <button
        className='sidebarButton'
        onClick={() => setSettingsExpanded(!settingsExpanded)}
      >
        <div className='sidebarButtonDiv'>
          <SettingOutlined style={{ fontSize: "30px" }} />
          <p>Settings</p>
        </div>
        {settingsExpanded && <UpOutlined />}
        {!settingsExpanded && <DownOutlined />}
      </button>
      {/*Settings Sub Buttons */}
      {settingsExpanded && (
        <div className='sidebarSubButtonContainer'>
          {/* Change Password Sub Button */}
          <button
            className='sidebarSubButton'
            onClick={() => navigate("/change-password")}
          >
            <LockOutlined />
            <p>Change Password</p>
          </button>
        </div>
      )}

      {/* Admin Button */}
      {user.isAdmin && (
        <>
          <button
            className='sidebarButton'
            onClick={() => setAdminExpanded(!adminExpanded)}
          >
            <div className='sidebarButtonDiv'>
              <BankOutlined style={{ fontSize: "30px" }} />

              <p>Admin</p>
            </div>
            {adminExpanded && <UpOutlined />}
            {!adminExpanded && <DownOutlined />}
          </button>
          {/*Settings Sub Buttons */}
          {adminExpanded && (
            <div className='sidebarSubButtonContainer'>
              {/* Events Sub Button */}
              <button
                className='sidebarSubButton'
                onClick={() => navigate("/admin/events")}
              >
                <ScheduleOutlined />
                <p>Events</p>
              </button>
              {/* Users Sub Button */}
              <button
                className='sidebarSubButton'
                onClick={() => navigate("/admin/users")}
              >
                <TeamOutlined />
                <p>Users</p>
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default DashboardSidebar;
