const trimString = (string) => {
  var l = 0;
  var r = string.length - 1;
  while (l < string.length && string[l] === " ") l++;
  while (r > l && string[r] === " ") r -= 1;
  return string.substring(l, r + 1);
};

const compareObjects = (o1, o2) => {
  var k = "";
  for (k in o1) if (o1[k] !== o2[k]) return false;
  for (k in o2) if (o1[k] !== o2[k]) return false;
  return true;
};

const itemExists = (haystack, needle) => {
  for (var i = 0; i < haystack.length; i++)
    if (compareObjects(haystack[i], needle)) return true;
  return false;
};

export const searchTable = (searchTerm, object) => {
  console.log("Object: ", object);
  var results = [];
  var toSearch = trimString(searchTerm).toLowerCase(); // Trim the string
  for (var i = 0; i < object.length; i++) {
    for (var key in object[i]) {
      if (typeof object[i][key] === "string") {
        if (object[i][key].toLowerCase().indexOf(toSearch) !== -1) {
          if (!itemExists(results, object[i])) results.push(object[i]);
        }
      }
      if (typeof object[i][key] === "object") {
        for (var keyTwo in object[i][key]) {
          if (object[i][key][keyTwo].toLowerCase().indexOf(toSearch) !== -1) {
            if (!itemExists(results, object[i])) results.push(object[i]);
          }
        }
      }
    }
  }
  return results;
};
