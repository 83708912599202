import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Layout from "../../components/layout/Layout";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { firebaseAuth } from "../../firebase";
import UserTable from "./components/UserTable";

function UserAdmin() {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (users.length === 0) {
      setLoading(true);
      firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND}/admin/users`,
            {
              headers: {
                Authorization: "Bearer " + idToken,
              },
            }
          );
          if (data) {
            setUsers(data);
          } else {
            toast.error("No users found");
          }
          setLoading(false);
        } catch (error) {
          console.log(error);
          toast.error("Error Retrieving Users");
        }
      });
    }
  }, [users.length]);

  return (
    <Layout title='Users (Sponsorship)'>
      <div className='py-8'>
        {loading ? (
          <div className='flex flex-col mt-10 w-full justify-center items-center'>
            <LoadingSpinner />
            <p className='mt-4'>Loading Users...</p>
          </div>
        ) : users.length === 0 ? (
          <p>No Users Found</p>
        ) : (
          <UserTable users={users} setUsers={setUsers} />
        )}
      </div>
    </Layout>
  );
}

export default UserAdmin;
