import React from "react";

function Footer() {
  return (
    <div className='flex justify-around py-4'>
      {process.env.NODE_ENV === "development" && (
        <>
          <p className='sm:hidden text-pink-500'>X-Small</p>
          <p className='hidden sm:block md:hidden text-pink-500'>Small</p>
          <p className='hidden md:block lg:hidden text-pink-500'>Medium</p>
          <p className='hidden lg:block xl:hidden text-pink-500'>Large</p>
          <p className='hidden xl:block 2xl:hidden text-pink-500'>X-Large</p>
          <p className='hidden 2xl:block text-pink-500'>2X-Large</p>
        </>
      )}
    </div>
  );
}

export default Footer;
