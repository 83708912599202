import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./reducers/authReducer";
import { eventReducer } from "./reducers/eventReducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    event: eventReducer,
  },
});
