import * as t from "../constants/eventConstants";

export const eventReducer = (
  state = {
    events: [],
    viewEvent: null,
    loaded: false,
    actionStatus: null,
  },
  action
) => {
  switch (action.type) {
    case t.GET_EVENTS:
      return {
        ...state,
        events: action.payload,
        loaded: true,
      };
    case t.CREATE_EVENT:
      return {
        ...state,
        events: [...state.events, action.payload],
      };
    case t.UPDATE_EVENT:
      const existingFilter = state.events.filter(
        (event) => event.id !== action.payload.id
      );
      return {
        ...state,
        events: [...existingFilter, action.payload],
        viewEvent: null,
      };
    case t.DELETE_EVENT:
      const filterEvents = state.events.filter(
        (event) => event.id !== action.payload.id
      );
      return {
        ...state,
        events: filterEvents,
      };
    case t.VIEW_EVENT:
      return {
        ...state,
        viewEvent: action.payload,
      };
    case t.ACTION_LOADING_EVENT:
      return {
        ...state,
        actionStatus: "LOADING",
      };
    case t.ACTION_COMPLETE_EVENT:
      return {
        ...state,
        actionStatus: "COMPLETE",
      };
    case t.ACTION_FAILED_EVENT:
      return {
        ...state,
        actionStatus: "FAILED",
      };
    case t.ACTION_RESET_EVENT:
      return {
        ...state,
        actionStatus: null,
      };
    default:
      return state;
  }
};
