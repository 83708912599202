import { LoadingOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import UnauthenticatedLayout from "../../components/layout/UnauthenticatedLayout";
import SelectField from "../../components/ui/SelectField";
import TextField from "../../components/ui/TextField";
import { createNewUser } from "../../redux/actions/authActions";

function RegisterScreen() {
  // Retrieve auth state from redux
  const authState = useSelector((state) => state.auth);
  const authActionStatus = authState.actionStatus;

  const [newUser, setNewUser] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    gender: "",
    dateOfBirth: "",
    marketingAccepted: false,
  });

  const dateOfBirthOnChangeHandler = (e) => {
    setNewUser({ ...newUser, dateOfBirth: e.target.value });
  };

  const dispatch = useDispatch();

  const registrationHandler = () => {
    // Function first validates user entry and then dispatches to redux actions to create user
    if (newUser.fullName.trim() === "") {
      toast.error("Please complete full name field");
      return;
    }
    if (newUser.email.trim() === "") {
      toast.error("Please complete email field");
      return;
    }
    if (newUser.password.trim() === "") {
      toast.error("Please complete password field");
      return;
    }
    if (newUser.confirmPassword.trim() === "") {
      toast.error("Please confirm your password");
      return;
    }
    if (newUser.password !== newUser.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    if (newUser.gender.trim() === "") {
      toast.error("Please complete gender field");
      return;
    }
    if (newUser.dateOfBirth.trim() === "") {
      toast.error("Please complete date of birth field");
      return;
    }
    dispatch(createNewUser(newUser));
  };

  return (
    <UnauthenticatedLayout>
      <div className='flex flex-col justify-center w-full md:w-6/12 px-10 py-32  md:px-10 lg:px-14 2xl:px-24 bg-youthsOrange text-white'>
        {/*  */}
        <h4 className='text-4xl text-center'>Get Your Event Sponsored.</h4>
        <p className='mt-12 text-xl'>
          Connect to thousands of opportunities. By simply signing up and
          submitting your event to us.
        </p>
      </div>
      <div className='flex flex-col w-full px-7 md:w-6/12'>
        <p className='mt-4'>
          Already signed up?{" "}
          <a href='/' className='text-youthsOrange underline'>
            Log In
          </a>
        </p>
        <form className='flex flex-col w-full'>
          <TextField
            label='Full Name'
            type='text'
            placeholder='Full Name'
            autoComplete='name'
            value={newUser.fullName}
            onChange={(e) =>
              setNewUser({ ...newUser, fullName: e.target.value })
            }
          />

          <TextField
            label='Email'
            type='email'
            placeholder='Email'
            autoComplete='username'
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
          />
          <TextField
            label='Password'
            type='password'
            placeholder='Password'
            autoComplete='new-password'
            value={newUser.password}
            onChange={(e) =>
              setNewUser({ ...newUser, password: e.target.value })
            }
          />
          <TextField
            label='Confirm Password'
            type='password'
            placeholder='Retype Password'
            autoComplete='new-password'
            value={newUser.confirmPassword}
            onChange={(e) =>
              setNewUser({ ...newUser, confirmPassword: e.target.value })
            }
          />
          <SelectField
            label='Gender'
            placeholder='Gender'
            value={newUser.gender}
            options={[
              {
                value: "MALE",
                text: "Male",
              },
              {
                value: "FEMALE",
                text: "Female",
              },
            ]}
            onChange={(e) => setNewUser({ ...newUser, gender: e.target.value })}
          />
          <TextField
            label='Date of Birth'
            type='date'
            showLabel
            flexRow
            placeholder='Date of Birth'
            value={newUser.dateOfBirth}
            onChange={(e) => dateOfBirthOnChangeHandler(e)}
          />
        </form>
        <div className='flex space-x-4'>
          <input
            id='marketing'
            type='checkbox'
            className='border'
            checked={newUser.marketingAccepted}
            onChange={() =>
              setNewUser({
                ...newUser,
                marketingAccepted: !newUser.marketingAccepted,
              })
            }
          />
          <label htmlFor='marketing' className='select-none'>
            I agree to receive newsletters and other promotional material.
          </label>
        </div>
        <button
          className='bg-youthsOrange text-white my-4 py-2 rounded-lg  '
          onClick={() => registrationHandler()}
          disabled={authActionStatus === "LOADING"}
        >
          {authActionStatus === "LOADING" ? <LoadingOutlined /> : "Join"}
        </button>
        <p className='mt-4'>
          By signing up, you agree to YouthsToday's Terms of Use and Privacy
          Policy.
        </p>
      </div>
    </UnauthenticatedLayout>
  );
}

export default RegisterScreen;
