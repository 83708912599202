import React, { useState } from "react";
import Footer from "./components/footers/Footer";
import Header from "./components/headers/Header";
import DashboardSidebar from "./components/sidebars/DashboardSidebar";
import MobileDashboardSidebar from "./components/sidebars/MobileDashboardSidebar";

function Layout({ children, title, border }) {
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  return (
    <div className='flex min-h-screen text-youthsGrey'>
      <DashboardSidebar />
      {showMobileSidebar && (
        <MobileDashboardSidebar setShowMobileSidebar={setShowMobileSidebar} />
      )}
      <div className='flex flex-col grow'>
        <Header setShowMobileSidebar={setShowMobileSidebar} />
        <div className='flex flex-col grow p-2 lg:p-4'>
          <h4 className='text-3xl'>{title}</h4>

          <div
            className={
              border ? "border border-black rounded-xl grow m-2 lg:m-4" : "grow"
            }
          >
            {children}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Layout;
