import React from "react";
import Footer from "./components/footers/Footer";
import UnauthenticatedHeader from "./components/headers/UnauthenticatedHeader";

function UnauthenticatedLayout({ children, hideSignUp }) {
  return (
    <div className='flex flex-col w-full min-h-screen text-youthsGrey'>
      <UnauthenticatedHeader hideSignUp={hideSignUp} />
      <div className='flex flex-col py-8 md:flex-row grow md:mx-20 lg:mx-28 xl:mx-36 2xl:mx-48'>
        {children}
      </div>
      <Footer />
    </div>
  );
}

export default UnauthenticatedLayout;
