import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Layout from "../../components/layout/Layout";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { firebaseAuth } from "../../firebase";
import EventTable from "./components/EventTable";

function EventAdmin() {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (events.length === 0) {
      setLoading(true);
      firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND}/admin/events`,
            {
              headers: {
                Authorization: "Bearer " + idToken,
              },
            }
          );
          if (data) {
            setEvents(data);
          } else {
            toast.error("No events found");
          }
          setLoading(false);
        } catch (error) {
          console.log(error);
          toast.error("Error Retrieving Events");
        }
      });
    }
  }, [events.length]);

  return (
    <Layout title='Events (Sponsorship)'>
      <div className='py-8'>
        {loading ? (
          <div className='flex flex-col mt-10 w-full justify-center items-center'>
            <LoadingSpinner />
            <p className='mt-4'>Loading Events...</p>
          </div>
        ) : events.length === 0 ? (
          <p>No Events Found</p>
        ) : (
          <EventTable events={events} />
        )}
      </div>
    </Layout>
  );
}

export default EventAdmin;
