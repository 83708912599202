import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/layout/Layout";
import EventCard from "./components/EventCard";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { getEvents } from "../../redux/actions/eventActions";

function MyEvents() {
  const eventState = useSelector((state) => state.event);
  const events = eventState.events;

  const dispatch = useDispatch();
  useEffect(() => {
    if (!eventState.loaded) {
      dispatch(getEvents());
    }
  }, [eventState.loaded, dispatch]);
  return (
    <Layout title='My Events'>
      {!eventState.loaded && (
        <div className='flex flex-col grow justify-center items-center'>
          <LoadingSpinner />
          <p className='mt-2'>Loading events...</p>
        </div>
      )}
      {events &&
        events.map((event) => <EventCard key={event.id} event={event} />)}
    </Layout>
  );
}

export default MyEvents;
