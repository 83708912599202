import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Layout from "../../components/layout/Layout";
import SelectField from "../../components/ui/SelectField";
import TextField from "../../components/ui/TextField";
import { format } from "date-fns";
import {
  fieldOfStudyOptions,
  qualificationOptions,
  schoolLocation,
} from "../../utils/selectOptions";
import { LoadingOutlined } from "@ant-design/icons";
import { updateEducation } from "../../redux/actions/authActions";

function EditEducation() {
  // Retrieve auth state from redux
  const authState = useSelector((state) => state.auth);

  const authActionStatus = authState.actionStatus;
  const educationDetails = authState.user.educationDetails;

  const [education, setEducation] = useState({
    qualification: "",
    schoolLocation: "",
    schoolName: "",
    fieldOfStudy: "",
    major: "",
    estimateGraduationDate: new Date(),
  });

  const dispatch = useDispatch();

  const saveClickHandler = () => {
    if (education.qualification.trim() === "") {
      toast.error("Please complete qualification field");
      return;
    }
    if (education.schoolLocation.trim() === "") {
      toast.error("Please complete school location field");
      return;
    }
    if (education.schoolName.trim() === "") {
      toast.error("Please complete school name field");
      return;
    }
    if (education.fieldOfStudy.trim() === "") {
      toast.error("Please complete field of study field");
      return;
    }
    if (education.major.trim() === "") {
      toast.error("Please complete major field");
      return;
    }
    dispatch(updateEducation(education));
  };

  useEffect(() => {
    setEducation({
      qualification: educationDetails.qualification,
      schoolLocation: educationDetails.schoolLocation,
      schoolName: educationDetails.schoolName,
      fieldOfStudy: educationDetails.fieldOfStudy,
      major: educationDetails.major,
      estimateGraduationDate: new Date(educationDetails.estimateGraduationDate),
    });
  }, [educationDetails]);

  return (
    <Layout title='Education' border>
      <form className='flex flex-col w-full lg:flex-row justify-between mt-4'>
        {/* Form Left (Form Top on mobile) */}
        <div className='flex flex-col w-full lg:w-6/12 px-2 lg:px-8'>
          <h4 className='text-2xl mb-4'>University</h4>
          {/* Qualification Select Field */}
          <SelectField
            label='Qualification:'
            showLabel
            value={education.qualification}
            placeholder='Select qualification'
            options={qualificationOptions}
            onChange={(e) =>
              setEducation({ ...education, qualification: e.target.value })
            }
          />
          {/* School Location Select Field */}
          <SelectField
            label='School Location:'
            showLabel
            value={education.schoolLocation}
            placeholder='Select location'
            options={schoolLocation}
            onChange={(e) =>
              setEducation({ ...education, schoolLocation: e.target.value })
            }
          />
          {/* School Name Text Field */}
          <TextField
            type='text'
            label='School Name:'
            showLabel
            value={education.schoolName}
            onChange={(e) =>
              setEducation({ ...education, schoolName: e.target.value })
            }
          />
        </div>
        {/* Form Right (Form Bottom on mobile) */}
        <div className='flex flex-col w-full lg:w-6/12 px-2 lg:px-8'>
          <h4 className='text-2xl mb-4'>Education</h4>
          {/* Field of Study Select Field */}
          <SelectField
            label='Field of Study:'
            showLabel
            value={education.fieldOfStudy}
            placeholder='Select field of study'
            options={fieldOfStudyOptions}
            onChange={(e) =>
              setEducation({ ...education, fieldOfStudy: e.target.value })
            }
          />
          {/* Major Text Field */}
          <TextField
            type='text'
            label='Major:'
            showLabel
            value={education.major}
            onChange={(e) =>
              setEducation({ ...education, major: e.target.value })
            }
          />
          <TextField
            type='date'
            label='Estimate Graduation Date:'
            showLabel
            value={format(
              new Date(education.estimateGraduationDate),
              "yyyy-MM-dd"
            )}
            onChange={(e) =>
              setEducation({
                ...education,
                estimateGraduationDate: e.target.value,
              })
            }
          />
        </div>
      </form>
      <button
        className='lg:absolute bg-youthsOrange text-white px-8 py-2 rounded-lg my-8 mx-2 lg:mx-8 right-8'
        onClick={() => saveClickHandler()}
        disabled={authActionStatus === "LOADING"}
      >
        {authActionStatus === "LOADING" ? <LoadingOutlined /> : "Save"}
      </button>
    </Layout>
  );
}

export default EditEducation;
