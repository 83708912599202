import { LoadingOutlined } from "@ant-design/icons";
import { sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "../../components/ui/Button";
import TextField from "../../components/ui/TextField";
import { firebaseAuth } from "../../firebase";

function ForgotPasswordScreen() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);

  const forgotPasswordHandler = () => {
    setLoading(true);
    sendPasswordResetEmail(firebaseAuth, email)
      .then(() => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        toast.error(`Error resetting password: ${error.code}`);
        setLoading(false);
      });
  };

  return (
    <div className='flex flex-col min-h-screen items-center justify-center px-4'>
      {!success && (
        <div className='flex flex-col w-full md:w-3/12'>
          <TextField
            label='Email Address'
            type='email'
            placeholder='Enter your email address'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            className='bg-youthsOrange text-white my-4 py-2 rounded-lg'
            onClick={() => forgotPasswordHandler()}
            disabled={loading}
          >
            {loading ? <LoadingOutlined /> : "Reset Password"}
          </button>
        </div>
      )}
      {success && (
        <div className='flex flex-col w-full md:w-3/12 bg-youthsOrange text-white p-8'>
          <p className='text-center'>
            Success - if the email address you entered is valid you will receive
            instructions on how to reset your password by email.
          </p>
        </div>
      )}
      <p className='mt-4'>
        Return to{" "}
        <a href='/' className='text-blue-500 underline'>
          Sign In
        </a>
      </p>
    </div>
  );
}

export default ForgotPasswordScreen;
