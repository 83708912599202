import React from "react";

function SelectField({
  label,
  value,
  placeholder,
  onChange,
  options,
  showLabel,
  flexRow,
}) {
  return (
    <div
      className={
        flexRow
          ? "flex flex-row items-center justify-between mt-2 mb-4"
          : "flex flex-col mt-2 mb-4"
      }
    >
      {showLabel && <label className='mb-2'>{label}</label>}
      <select
        className={
          flexRow
            ? "w-8/12 p-2 h-10 border rounded-lg"
            : "w-full p-2 h-10 border rounded-lg"
        }
        value={value}
        onChange={onChange}
      >
        <option disabled value=''>
          {placeholder}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SelectField;
