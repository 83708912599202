import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import SelectField from "../../components/ui/SelectField";
import TextField from "../../components/ui/TextField";
import { eventTypeOptions } from "../../utils/selectOptions";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { createEvent } from "../../redux/actions/eventActions";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function CreateEvent() {
  const eventState = useSelector((state) => state.event);
  const eventActionStatus = eventState.actionStatus;

  const [event, setEvent] = useState({
    eventTitle: "",
    eventType: "",
    eventStartDate: new Date(),
    eventEndDate: new Date(),
    eventVenueAddress: "",
    eventDescription: "",
    estimatedCrowdSize: "",
    cashRequired: "",
    clubName: "",
    clubMembers: "",
    contactName: "",
    contactNumber: "",
    contactEmail: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createClickHandler = () => {
    console.log(event);
    if (event.eventTitle.trim() === "") {
      toast.error("Please complete event title field");
      return;
    }
    if (event.eventType.trim() === "") {
      toast.error("Please complete event type field");
      return;
    }
    if (event.eventVenueAddress.trim() === "") {
      toast.error("Please complete event venue address field");
      return;
    }
    if (event.eventDescription.trim() === "") {
      toast.error("Please provide event description");
      return;
    }
    if (event.estimatedCrowdSize.trim() === "") {
      toast.error("Please provide estimated crowd size");
      return;
    }
    if (event.cashRequired.trim() === "") {
      toast.error("Please provide how much sponsorship you need");
      return;
    }
    if (event.clubName.trim() === "") {
      toast.error("Please provide club name");
      return;
    }
    if (event.clubMembers.trim() === "") {
      toast.error("Please provide committee members");
      return;
    }
    if (event.contactName.trim() === "") {
      toast.error("Please provide contact name");
      return;
    }
    if (event.contactNumber.trim() === "") {
      toast.error("Please provide contact number");
      return;
    }
    if (event.contactEmail.trim() === "") {
      toast.error("Please provide contact email");
      return;
    }
    dispatch(createEvent(event));
  };

  useEffect(() => {
    if (eventActionStatus === "COMPLETE") {
      navigate("/my-events");
    }
  }, [eventActionStatus, navigate]);
  return (
    <Layout title='Create Event'>
      {/* Create Event - First Box */}
      <div className='border border-black rounded-xl grow m-2 lg:m-4'>
        <form className='flex flex-col w-full lg:flex-row justify-between mt-4'>
          {/* Form Left (Form Top on Mobile) */}
          <div className='flex flex-col w-full lg:w-6/12 px-2 lg:px-8'>
            <TextField
              type='text'
              label='Event Title:'
              showLabel
              flexRow
              value={event.eventTitle}
              onChange={(e) =>
                setEvent({ ...event, eventTitle: e.target.value })
              }
            />
            <SelectField
              label='Event Type: '
              showLabel
              flexRow
              value={event.eventType}
              placeholder='Select Event Type'
              options={eventTypeOptions}
              onChange={(e) =>
                setEvent({ ...event, eventType: e.target.value })
              }
            />
          </div>
          {/* Form Right (Form Bottom on Mobile) */}
          <div className='flex flex-col w-full lg:w-6/12 px-2 lg:px-8'>
            <TextField
              type='date'
              label='Event Start Date: '
              showLabel
              flexRow
              value={format(new Date(event.eventStartDate), "yyyy-MM-dd")}
              onChange={(e) =>
                setEvent({ ...event, eventStartDate: e.target.value })
              }
            />
            <TextField
              type='date'
              label='Event End Date:'
              showLabel
              flexRow
              value={format(new Date(event.eventEndDate), "yyyy-MM-dd")}
              onChange={(e) =>
                setEvent({ ...event, eventEndDate: e.target.value })
              }
            />
          </div>
        </form>
        <form className='flex flex-col w-full mt-4'>
          <div className='flex flex-col w-full px-2 lg:px-8'>
            <TextField
              type='text'
              label='Event Venue Address: '
              showLabel
              flexRow
              value={event.eventVenueAddress}
              onChange={(e) =>
                setEvent({ ...event, eventVenueAddress: e.target.value })
              }
            />
            <div className='flex flex-col mt-2 mb-4'>
              <label className='mb-2'>Event Description: </label>
              <textarea
                className='w-full p-2 border rounded-lg'
                value={event.eventDescription}
                type='text'
                placeholder='Tell us about your event'
                onChange={(e) =>
                  setEvent({ ...event, eventDescription: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-col w-full lg:flex-row justify-between mt-4'>
            <div className='flex flex-col w-full lg:w-6/12 px-2 lg:px-8'>
              <TextField
                type='text'
                label='Estimated Crowd Size: '
                showLabel
                flexRow
                value={event.estimatedCrowdSize}
                onChange={(e) =>
                  setEvent({ ...event, estimatedCrowdSize: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col w-full lg:w-6/12 px-2 lg:px-8'>
              <TextField
                type='text'
                label='How much cash sponsorship do you need? '
                showLabel
                flexRow
                value={event.cashRequired}
                onChange={(e) =>
                  setEvent({ ...event, cashRequired: e.target.value })
                }
              />
            </div>
          </div>
        </form>
      </div>
      {/* Create Event Second Box */}
      <div className='border border-black rounded-xl grow m-2 lg:m-4'>
        <form className='flex flex-col w-full lg:flex-row justify-between mt-4'>
          {/* Form Left (Form Top on Mobile) */}
          <div className='flex flex-col w-full lg:w-6/12 px-2 lg:px-8'>
            <TextField
              label='What is your Club Name?'
              showLabel
              value={event.clubName}
              onChange={(e) => setEvent({ ...event, clubName: e.target.value })}
            />
          </div>
          {/* Form Left (Form Top on Mobile) */}
          <div className='flex flex-col w-full lg:w-6/12 px-2 lg:px-8'>
            <TextField
              label='How many committee members do you have?'
              showLabel
              value={event.clubMembers}
              onChange={(e) =>
                setEvent({ ...event, clubMembers: e.target.value })
              }
            />
          </div>
        </form>
      </div>
      {/* Create Event Third Box */}
      <div className='border border-black rounded-xl grow m-2 lg:m-4'>
        <form className='flex flex-col w-full lg:flex-row justify-between mt-4'>
          {/* Form Left (Form Top on Mobile) */}
          <div className='flex flex-col w-full lg:w-6/12 px-2 lg:px-8'>
            <TextField
              type='text'
              label='Contact Name: '
              showLabel
              flexRow
              value={event.contactName}
              onChange={(e) =>
                setEvent({ ...event, contactName: e.target.value })
              }
            />
            <TextField
              type='email'
              label='Contact Email: '
              showLabel
              flexRow
              value={event.contactEmail}
              onChange={(e) =>
                setEvent({ ...event, contactEmail: e.target.value })
              }
            />
          </div>
          {/* Form Left (Form Top on Mobile) */}
          <div className='flex flex-col w-full lg:w-6/12 px-2 lg:px-8'>
            <TextField
              type='tel'
              label='Contact Number: '
              showLabel
              flexRow
              value={event.contactNumber}
              onChange={(e) =>
                setEvent({ ...event, contactNumber: e.target.value })
              }
            />
          </div>
        </form>
      </div>
      <div className='flex w-full justify-center'>
        <button
          className='bg-youthsOrange text-white px-8 py-2 rounded-lg my-2'
          onClick={() => createClickHandler()}
          disabled={eventActionStatus === "LOADING"}
        >
          {eventActionStatus === "LOADING" ? <LoadingOutlined /> : "Create"}
        </button>
      </div>
    </Layout>
  );
}

export default CreateEvent;
