import React, { useState } from "react";
import UnauthenticatedLayout from "../../components/layout/UnauthenticatedLayout";
import SelectField from "../../components/ui/SelectField";
import TextField from "../../components/ui/TextField";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { createEducation } from "../../redux/actions/authActions";
import {
  fieldOfStudyOptions,
  qualificationOptions,
  schoolLocation,
} from "../../utils/selectOptions";
import { LoadingOutlined } from "@ant-design/icons";

function RegisterStepTwo() {
  // Retrieve auth state from redux
  const authState = useSelector((state) => state.auth);
  const authActionStatus = authState.actionStatus;

  const [education, setEducation] = useState({
    qualification: "",
    schoolLocation: "",
    schoolName: "",
    fieldOfStudy: "",
    major: "",
    estimateGraduationDate: new Date(),
  });

  const dispatch = useDispatch();

  const doneClickHandler = () => {
    if (education.qualification.trim() === "") {
      toast.error("Please complete qualification field");
      return;
    }
    if (education.schoolLocation.trim() === "") {
      toast.error("Please complete school location field");
      return;
    }
    if (education.schoolName.trim() === "") {
      toast.error("Please complete school name field");
      return;
    }
    if (education.fieldOfStudy.trim() === "") {
      toast.error("Please complete field of study field");
      return;
    }
    if (education.major.trim() === "") {
      toast.error("Please complete major field");
      return;
    }
    dispatch(createEducation(education));
  };

  return (
    <UnauthenticatedLayout hideSignUp>
      <div className='flex flex-col items-center w-full px-2'>
        {/* Step Bar */}
        <div className='flex w-full border border-youthsGrey sm:p-3'>
          <div className='flex flex-col w-6/12 items-center justify-center lg:text-lg xl:text-xl py-8'>
            <p>Step One:</p>
            <p>User Information</p>
          </div>
          <div className='flex flex-col w-6/12 items-center justify-center bg-youthsOrange text-white lg:text-lg xl:text-xl py-8'>
            <p>Step Two:</p>
            <p>Education</p>
          </div>
        </div>
        {/* Form */}
        <form className='flex flex-col w-full lg:flex-row justify-between mt-4'>
          {/* Form Left (Form Top on mobile) */}
          <div className='flex flex-col w-full lg:w-6/12 px-2 lg:px-8'>
            <h4 className='text-2xl mb-4'>University</h4>
            {/* Qualification Select Field */}
            <SelectField
              label='Qualification:'
              showLabel
              value={education.qualification}
              placeholder='Select qualification'
              options={qualificationOptions}
              onChange={(e) =>
                setEducation({ ...education, qualification: e.target.value })
              }
            />
            {/* School Location Select Field */}
            <SelectField
              label='School Location:'
              showLabel
              value={education.schoolLocation}
              placeholder='Select location'
              options={schoolLocation}
              onChange={(e) =>
                setEducation({ ...education, schoolLocation: e.target.value })
              }
            />
            {/* School Name Text Field */}
            <TextField
              type='text'
              label='School Name:'
              showLabel
              value={education.schoolName}
              onChange={(e) =>
                setEducation({ ...education, schoolName: e.target.value })
              }
            />
          </div>
          {/* Form Right (Form Bottom on mobile) */}
          <div className='flex flex-col w-full lg:w-6/12 px-2 lg:px-8'>
            <h4 className='text-2xl mb-4'>Education</h4>
            {/* Field of Study Select Field */}
            <SelectField
              label='Field of Study:'
              showLabel
              value={education.fieldOfStudy}
              placeholder='Select field of study'
              options={fieldOfStudyOptions}
              onChange={(e) =>
                setEducation({ ...education, fieldOfStudy: e.target.value })
              }
            />
            {/* Major Text Field */}
            <TextField
              type='text'
              label='Major:'
              showLabel
              value={education.major}
              onChange={(e) =>
                setEducation({ ...education, major: e.target.value })
              }
            />
            <TextField
              type='date'
              label='Estimate Graduation Date:'
              showLabel
              value={format(
                new Date(education.estimateGraduationDate),
                "yyyy-MM-dd"
              )}
              onChange={(e) =>
                setEducation({
                  ...education,
                  estimateGraduationDate: e.target.value,
                })
              }
            />
          </div>
        </form>
        <button
          className='bg-youthsOrange text-white px-8 py-2 rounded-lg mt-8'
          onClick={() => doneClickHandler()}
          disabled={authActionStatus === "LOADING"}
        >
          {authActionStatus === "LOADING" ? <LoadingOutlined /> : "Done"}
        </button>
      </div>
    </UnauthenticatedLayout>
  );
}

export default RegisterStepTwo;
