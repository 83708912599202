import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  markNotAuthenticated,
  signInUserToken,
} from "./redux/actions/authActions";
import LoadingSpinner from "./components/ui/LoadingSpinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RegisterStepTwo from "./routes/Register/RegisterStepTwo";
import { onAuthStateChanged } from "firebase/auth";
import { firebaseAuth } from "./firebase";

// Unauthenticated Routes
import LoginScreen from "./routes/Login";
import RegisterScreen from "./routes/Register";

// Incomplete Profile Routes
import RegisterStepOne from "./routes/Register/RegisterStepOne";
import ForgotPasswordScreen from "./routes/ForgotPassword";

// Authenticated Routes
import EditProfile from "./routes/Profile/EditProfile";
import EditEducation from "./routes/Profile/EditEducation";
import CreateEvent from "./routes/Sponsorship/CreateEvent";
import MyEvents from "./routes/Sponsorship/MyEvents";
import ViewEvent from "./routes/Sponsorship/ViewEvent";
import ChangePassword from "./routes/Settings/ChangePassword";
import UserAdmin from "./routes/Admin/UserAdmin";
import EventAdmin from "./routes/Admin/EventAdmin";
import LogRocket from "logrocket";
import EditEvent from "./routes/Admin/EditEvent";
LogRocket.init("youthstoday/sponsorship");

function App() {
  const auth = useSelector((state) => state.auth);
  LogRocket.identify(auth?.user?.id, {
    name: auth?.user?.fullName,
    email: auth?.user?.email,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.signedIn === null) {
      const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
        if (user) {
          dispatch(signInUserToken(user.accessToken));
        } else {
          dispatch(markNotAuthenticated());
        }
      });
      unsubscribe();
    }
  }, [auth, dispatch]);

  return (
    <BrowserRouter>
      {auth.signedIn === null ? (
        <div className='flex flex-col w-full h-screen justify-center items-center'>
          <LoadingSpinner />
          <p className='mt-4'>Welcome to YouthsToday</p>
        </div>
      ) : auth.signedIn ? (
        <>
          {!auth.user.registrationStepOne ? (
            // Incomplete profile route
            <Routes>
              <Route path='/' element={<RegisterStepOne />} />
              <Route path='*' element={<Navigate to='/' />} />
            </Routes>
          ) : (
            <>
              {!auth.user.registrationStepTwo ? (
                // Incomplete profile route (education not complete)
                <Routes>
                  <Route path='/' element={<RegisterStepTwo />} />
                  <Route path='*' element={<Navigate to='/' />} />
                </Routes>
              ) : (
                // Authenticated Routes
                <Routes>
                  {auth.user.isAdmin && (
                    // Admin only routes
                    <>
                      <Route path='/admin/events' element={<EventAdmin />} />
                      <Route path='/admin/users' element={<UserAdmin />} />
                      <Route path='/admin/edit-event'>
                        <Route path=':eventId' element={<EditEvent />} />
                      </Route>
                    </>
                  )}
                  <Route path='/' element={<EditProfile />} />
                  <Route path='/education' element={<EditEducation />} />
                  <Route path='/create-event' element={<CreateEvent />} />
                  <Route path='/my-events' element={<MyEvents />} />
                  <Route path='/view-event'>
                    <Route path=':eventId' element={<ViewEvent />} />
                  </Route>
                  <Route path='/change-password' element={<ChangePassword />} />
                  <Route path='*' element={<Navigate to='/' />} />
                </Routes>
              )}
            </>
          )}
        </>
      ) : (
        // Unauthenticated Routes
        <Routes>
          <Route path='/' element={<LoginScreen />} />
          <Route path='/register' element={<RegisterScreen />} />
          <Route path='/forgot-password' element={<ForgotPasswordScreen />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      )}
      <ToastContainer autoClose={2000} position='bottom-left' />
    </BrowserRouter>
  );
}

export default App;
